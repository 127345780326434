import { DynamicRouteData, SiteWixCodeSdkFactoryData, WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { MetaTag, SiteLevelSeoData } from 'feature-seo'
import { SeoWixCodeSdkFactoryData, SeoWixCodeSdkHandlers, SeoWixCodeSdkWixCodeApi, Link, namespace } from '..'
import { initState } from './utils/generate-state'
import { mediaItemUtils } from 'santa-platform-utils'
import { extractDynamicRouteData } from './utils/extract-dynamic-route-data'
import { resolveMetaTags } from './utils/resolve-meta-tags'

/**
 * SEO SDK Factory
 * API Docs: https://www.wix.com/corvid/reference/wix-seo.html
 */

export function SeoSdkFactory({
	featureConfig: pageLevelSeoData,
	handlers,
	platformEnvData,
}: WixCodeApiFactoryArgs<SiteWixCodeSdkFactoryData, SeoWixCodeSdkFactoryData, SeoWixCodeSdkHandlers>): {
	[namespace]: SeoWixCodeSdkWixCodeApi
} {
	const {
		setTitle,
		setLinks,
		setMetaTags,
		setSeoStatusCode,
		setStructuredData,
		renderSEOTags,
		resetSEOTags,
		onTPAOverrideChanged,
	} = handlers
	const siteLevelSeoData = platformEnvData.seo as SiteLevelSeoData
	const dynamicRoutePayload = platformEnvData.router.dynamicRouteData as DynamicRouteData
	const { dynamicPageData, corvidOverrides } = extractDynamicRouteData(dynamicRoutePayload, mediaItemUtils) || {}
	const { state, setCorvidState, setState } = initState({
		siteLevelSeoData,
		pageLevelSeoData,
		corvidOverrides,
		dynamicPageData,
	})

	if (process.env.browser) {
		onTPAOverrideChanged((tpaOverrides) => {
			state.tpaOverrides = tpaOverrides
		})
	}

	return {
		[namespace]: {
			get title() {
				return state.corvid.title
			},
			get links() {
				return state.corvid.links
			},
			get metaTags() {
				return state.corvid.metaTags
			},
			get structuredData() {
				return state.corvid.structuredData
			},
			get seoStatusCode() {
				return state.corvid.seoStatusCode
			},
			isInSEO() {
				return siteLevelSeoData.isInSEO
			},
			async setTitle(title: string) {
				setTitle(title)
				setCorvidState({ title })
			},
			async setLinks(links: Array<Link>) {
				setLinks(links)
				setCorvidState({ links })
			},
			async setMetaTags(metaTags: Array<MetaTag>) {
				const resolvedMetaTags = resolveMetaTags(metaTags, mediaItemUtils) as Array<MetaTag>
				setMetaTags(resolvedMetaTags)
				setCorvidState({ metaTags: resolvedMetaTags })
			},
			async setStructuredData(structuredData: Array<Record<string, any>>) {
				setStructuredData(structuredData)
				setCorvidState({ structuredData })
			},
			async setSeoStatusCode(seoStatusCode: number) {
				setSeoStatusCode(seoStatusCode)
				setCorvidState({ seoStatusCode })
			},
			async renderSEOTags(payload) {
				renderSEOTags(payload)
				await setState({
					corvidState: state.corvid,
					corvidItemPayload: payload,
				})
			},
			async resetSEOTags() {
				resetSEOTags()
				await setState({
					corvidState: state.userOverrides,
				})
			},
		},
	}
}
